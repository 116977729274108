<template>
  <div class="g-input">
    <span
      v-if="placeholder && !nativePlaceholder"
      class="g-input__placeholder"
    >
      {{ placeholder }}
    </span>
    <textarea-autosize
      v-if="isTextarea"
      :disabled="disabled"
      :max-rows="textareaRows > textareaMaxRows ? textareaRows : textareaMaxRows"
      :placeholder="nativePlaceholder ? placeholder : ''"
      :readonly="readonly"
      :rows="textareaRows"
      :value="value"
      class="g-input__input"
      @input="onInput"
      @focus.native="onFocus"
      @blur.native="onBlur"
    />
    <input
      v-else
      :disabled="disabled"
      :placeholder="nativePlaceholder ? placeholder : ''"
      :readonly="readonly"
      :type="type"
      :value="value"
      :class="{'error-border': error}"
      class="g-input__input"
      @blur="onBlur"
      @focus="onFocus"
      @input="onInput"
    >
    <Cross
      v-if="error"
      :style="{
      position: 'absolute',
      right: '15px',
      top: '50%',
      transform: 'translateY(-50%)'
      }"
    />
    <div
      v-if="showCharacterCount || showCharacterCountOnFocus"
      :class="[
        showCharacterCountOnFocus
          ? (inputActive ? 'g-input__character-count--active' : 'g-input__character-count--hidden')
          : '',
        { 'g-input__character-count--on-left': characterCountOnLeft }
      ]"
      class="g-input__character-count"
    >
      <span class="current">{{ value ? value.toString().length : 0 }}</span>
      <template v-if="maxCharacters">
        <span>/</span>
        <span class="max">{{ maxCharacters }}</span>
      </template>
    </div>
    <slot/>
  </div>
</template>

<script>
import Cross from "@/components/ui/icons/Cross";

export default {
  name: "vn-input",

  components: {
    Cross
  },

  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    nativePlaceholder: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "normal"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    isTextarea: {
      type: Boolean,
      default: false
    },
    textareaMaxRows: {
      type: Number,
      default: 5
    },
    textareaRows: {
      type: Number,
      default: 1
    },
    maxCharacters: {
      type: Number,
      default: null
    },
    showCharacterCount: {
      type: Boolean,
      default: false
    },
    showCharacterCountOnFocus: {
      type: Boolean,
      default: false
    },
    characterCountOnLeft: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isFocused: false
    }
  },

  watch: {
    inputActive() {
      this.$emit('set-activity', this.inputActive)
    },

    isPassword() {
      return this.type === "password"
    }
  },

  computed: {
    inputActive() {
      return this.isFocused || !!this.value
    }
  },

  methods: {
    onFocus() {
      this.isFocused = true
    },

    onBlur() {
      this.isFocused = false
    },

    onInput(e) {
      if (this.isTextarea) {
        this.$emit('input', this.maxCharacters ? e.slice(0, this.maxCharacters) : e)
      } else {
        this.$emit('input', this.maxCharacters ? e.target.value.slice(0, this.maxCharacters) : e.target.value)
      }
    }
  }
}
</script>

<style lang="scss">
.g-input {
  position: relative;

  &__input {
    width: 100%;
    font-size: 16px;
    line-height: 120%;
    border: 1px solid #d6dee2;
    color: #000000;
    background: #ffffff;
    border-radius: 6px;
    padding: 20px 16px 5px;
    transition: border-color 0.3s ease;

    &:focus {
      border-color: #8d9ca8;
    }
  }

  &__placeholder {
    position: absolute;
    top: 10px;
    left: 16px;
    max-width: calc(100% - 20px);
    background: #ffffff;
    border-radius: 50px;
    font-size: 12px;
    line-height: 100%;
    pointer-events: none;
    color: #8d9ca8;
    transition: top 0.3s ease, transform 0.3s ease;
    transform: translateY(-30%);
  }

  &__character-count {
    position: absolute;
    bottom: 8px;
    right: 10px;
    font-size: 12px;
    line-height: 100%;
    border-radius: 50px;
    background: #ffffff;
    color: #000000;
    transform: translateY(50%);
    transition: opacity 0.3s ease;

    &--hidden {
      opacity: 0;
    }

    &--active {
      opacity: 1;
    }

    &--on-left {
      right: unset;
      left: 10px;
    }
  }
}

.error-border {
  border-color: red;
}
</style>
