var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"g-input"},[(_vm.placeholder && !_vm.nativePlaceholder)?_c('span',{staticClass:"g-input__placeholder"},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")]):_vm._e(),(_vm.isTextarea)?_c('textarea-autosize',{staticClass:"g-input__input",attrs:{"disabled":_vm.disabled,"max-rows":_vm.textareaRows > _vm.textareaMaxRows ? _vm.textareaRows : _vm.textareaMaxRows,"placeholder":_vm.nativePlaceholder ? _vm.placeholder : '',"readonly":_vm.readonly,"rows":_vm.textareaRows,"value":_vm.value},on:{"input":_vm.onInput},nativeOn:{"focus":function($event){return _vm.onFocus.apply(null, arguments)},"blur":function($event){return _vm.onBlur.apply(null, arguments)}}}):_c('input',{staticClass:"g-input__input",class:{'error-border': _vm.error},attrs:{"disabled":_vm.disabled,"placeholder":_vm.nativePlaceholder ? _vm.placeholder : '',"readonly":_vm.readonly,"type":_vm.type},domProps:{"value":_vm.value},on:{"blur":_vm.onBlur,"focus":_vm.onFocus,"input":_vm.onInput}}),(_vm.error)?_c('Cross',{style:({
    position: 'absolute',
    right: '15px',
    top: '50%',
    transform: 'translateY(-50%)'
    })}):_vm._e(),(_vm.showCharacterCount || _vm.showCharacterCountOnFocus)?_c('div',{staticClass:"g-input__character-count",class:[
      _vm.showCharacterCountOnFocus
        ? (_vm.inputActive ? 'g-input__character-count--active' : 'g-input__character-count--hidden')
        : '',
      { 'g-input__character-count--on-left': _vm.characterCountOnLeft }
    ]},[_c('span',{staticClass:"current"},[_vm._v(_vm._s(_vm.value ? _vm.value.toString().length : 0))]),(_vm.maxCharacters)?[_c('span',[_vm._v("/")]),_c('span',{staticClass:"max"},[_vm._v(_vm._s(_vm.maxCharacters))])]:_vm._e()],2):_vm._e(),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }